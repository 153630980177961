export const path = '';
export const getRoute = [
  {
    path: `${path}/`,
    component: () => import('../pages/index.vue'),
    name: 'home',
    meta: {
      title: '',
      description: '',
      keywords: '',
      footer: '',
    },
  },
  {
    path: `${path}/product/`,
    component: () => import('../pages/product.vue'),
    name: 'product',
    meta: {
      title: '',
      description: '',
      keywords: '',
      footer: 'bg-[#fff]',
    },
  },
  {
    path: `${path}/group/`,
    name: 'group',
    component: () => import('../pages/group.vue'),
    meta: {
      title: '',
      description: '',
      keywords: '',
      footer: 'relative bg-[#c10c09] text-[#fff]',
    },
  },
  {
    path: `${path}/tutorial/`,
    component: () => import('../pages/use.vue'),
    name: 'tutorial',
    meta: {
      title: '',
      description: '',
      keywords: '',
      footer: 'relative bg-[#c10c09] text-[#fff]',
    },
  },
  {
    path: `${path}/map/`,
    component: () => import('../pages/map.vue'),
    name: 'map',
    meta: {
      title: '',
      description: '',
      keywords: '',
      footer: 'absolute text-[#fff]',
    },
  },
  {
    path: `${path}/news/`,
    component: () => import('../pages/news.vue'),
    name: 'news',
    meta: {
      title: '',
      description: '',
      keywords: '',
      footer: 'absolute text-[#fff]',
    },
  },
  {
    path: `${path}/topic/`,
    component: () => import('../pages/topic.vue'),
    name: 'topic',
    meta: {
      title: '',
      description: '',
      keywords: '',
      footer: 'relative text-[#000]',
    },
  },
];
