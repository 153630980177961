import { ViteSSG } from 'vite-ssg';
import App from '@/App.vue';
import {
  setGSAP,
  setScrollTrigger,
  setLenis,
  setLoading,
  getScrollTrigger,
} from '@composable/useGsap';
import { getRoute } from '@composable/useRoute';
// import gsap from 'gsap';
// import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import '@css/style.css';
import '@css/common.css';
import '@css/anim.css';

const routes = getRoute;

const scrollBehavior = (to, from, savedPosition) => {
  return { top: 0 };
};
export const createApp = ViteSSG(
  App,
  { routes, scrollBehavior },
  async ({ app, router, routes, isClient, initialState }) => {
    if (isClient) {
      let gsap, ScrollTrigger, lenis, Lenis;
      setLoading(true);

      const initLenis = async () => {
        try {
          const LenisModule = await import('lenis');
          Lenis = LenisModule.default || LenisModule;
          lenis = new Lenis();
          setLenis(lenis);

          if (ScrollTrigger && ScrollTrigger.update) {
            lenis.on('scroll', ScrollTrigger.update);
          } else {
          }

          if (gsap) {
            gsap.ticker.add((time) => {
              lenis.raf(time * 1000);
            });
            gsap.ticker.lagSmoothing(0);
          }
          // lenis.on('scroll', ({ scroll }) => {
          //   console.log('Scroll:', scroll);
          // });
          lenis.scrollTo(0, { immediate: true });
        } catch (error) {}
      };

      try {
        const gsapModule = await import('gsap');
        const ScrollTriggerModule = await import('gsap/ScrollTrigger');

        gsap = gsapModule.default;
        setGSAP(gsap);

        ScrollTrigger = ScrollTriggerModule.default;
        gsap.registerPlugin(ScrollTrigger);
        setScrollTrigger(ScrollTrigger);

        await initLenis();

        const { Swiper, SwiperSlide } = await import('swiper/vue');
        app.component('Swiper', Swiper);
        app.component('SwiperSlide', SwiperSlide);

        await import('swiper/css');
        await import('swiper/css/navigation');
        await import('swiper/css/pagination');

        setLoading(false);
      } catch (error) {
        console.error(
          'Error loading GSAP, ScrollTrigger, Lenis or Swiper:',
          error
        );
      }

      router.beforeEach((to, from, next) => {
        // document.title = to.meta.title || '';
        // const metaDescription = document.querySelector(
        //   'meta[name="description"]'
        // );
        // if (metaDescription) {
        //   metaDescription.setAttribute('content', to.meta.description || '');
        // }
        // const metaKeywords = document.querySelector('meta[name="keywords"]');
        // if (metaKeywords) {
        //   metaKeywords.setAttribute('content', to.meta.keywords || '');
        // }
        next();
      });

      router.afterEach((to, from) => {
        if (lenis) {
          lenis.destroy();
        }
        // if (ScrollTrigger) {
        //   ScrollTrigger.getAll().forEach((t) => t.kill());
        //   ScrollTrigger.clearMatchMedia();
        // }
        // 使用 Promise 來確保滾動完成後再初始化 Lenis
        return new Promise((resolve) => {
          requestAnimationFrame(() => {
            setTimeout(async () => {
              await initLenis();
              if (ScrollTrigger) {
                ScrollTrigger.refresh();
              }
              resolve();
              setLoading(false);
            }, 100);
          });
        });
      });
    }
  }
);
