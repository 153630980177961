<script setup>
import { useWindowScroll } from '@vueuse/core';
import { useWindowSize } from '@vueuse/core';
import { useRoute } from 'vue-router';
const { y } = useWindowScroll();
const { width } = useWindowSize();
const topPos = ref(300);
const isDarkNav = ref(true);
const active = ref(null);
const winSize = ref(1125);
const route = useRoute();

const path = '/demo';

const toggleMenu = () => {
  active.value = !active.value;
};

const navs = reactive([
  {
    name: '原蔘商品',
    to: { name: 'product' },
  },
  {
    name: '免疫力調查白皮書',
    to: { name: 'topic' },
  },
  {
    name: '最新消息',
    to: { name: 'news' },
  },
  {
    name: '養蔘族群',
    to: { name: 'group' },
  },
  {
    name: '養蔘怎麼吃',
    to: { name: 'tutorial' },
  },
  {
    name: '店內諮詢',
    to: { name: 'map' },
  },
]);

const updateNavColor = () => {
  const sections = document.querySelectorAll('.section');
  for (const section of sections) {
    const rect = section.getBoundingClientRect();
    if (rect.top <= 50 && rect.bottom > 50) {
      isDarkNav.value = section.getAttribute('navColor') === 'dark';
      break;
    }
  }

  if (y.value < topPos.value && active.value && width > winSize) toggleMenu();
};

onMounted(() => {
  window.addEventListener('scroll', updateNavColor);
  updateNavColor(); // 初始化導航欄顏色
});

watch(route, (to) => {
  updateNavColor();
});
onUnmounted(() => {
  window.removeEventListener('scroll', updateNavColor);
});
</script>

<template>
  <header
    class="flex fixed top-0 w-full z-[200] transition-all p:items-center p:px-[55px] p:h-[100px] tm:pl-[20px] tm:pr-[64px] tm:py-[30px] tm:h-[210px]"
  >
    <router-link
      :to="{ name: 'home' }"
      class="flex-shrink-0 p:w-[110px] p:mt-[50px] p:ml-[-40px] tm:w-[220px]"
    >
      <Img images="logo.png" />
    </router-link>
    <!-- fix nav -->
    <nav
      class="fix_nav transition-all opacity-0 fixed w-full h-full top-0 left-0 flex justify-center items-center pointer-events-none"
      :class="{ 'is-active opacity-100 pointer-events-auto': active }"
    >
      <ul class="relative z-[1] w-full px-[200px]">
        <li
          v-for="(nav, index) in navs"
          :key="`nav_${index}`"
          class="transition-all p:text-[25px] tm:text-[52px] text-[#fff] text-center opacity-0 duration-[0.8s]"
          :class="{
            'is-active opacity-100': active,
          }"
          @click="active = false"
        >
          <router-link :to="nav.to" class="block p:py-[40px] tm:py-[100px]">
            <p class="font-bold">{{ nav.name }}</p>
          </router-link>
        </li>
      </ul>
    </nav>

    <!-- top nav -->
    <div
      class="flex-grow flex justify-end p:items-center tm:mt-[50px] transition-all"
      :class="{
        'opacity-0 pointer-events-none': y > topPos && width > winSize,
      }"
    >
      <nav class="transition-all tm:hidden">
        <ul
          class="p:flex p:justify-end p:items-center p:mx-[-40px]"
          :class="{
            'text-[#fff]': !isDarkNav,
          }"
        >
          <li
            v-for="(nav, index) in navs"
            :key="`nav_${index}`"
            class="transition-all p:px-[40px] p:text-[15px]"
          >
            <router-link :to="nav.to" class="block">
              <p class="font-bold">{{ nav.name }}</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <button
        type="button"
        class="btn_menu p:hidden tm:relative tm:block tm:w-[100px] tm:h-[50px]"
        @click="toggleMenu"
      >
        <i
          class="top-0"
          :class="{
            'bg-[#000]': isDarkNav && !active,
            'bg-[#fff]': !isDarkNav || active,
            'rotate-[-30deg] -translate-y-1/2 !top-1/2': active,
          }"
        ></i>
        <i
          class="-translate-y-1/2 top-1/2"
          :class="{
            'bg-[#000]': isDarkNav && !active,
            'bg-[#fff]': !isDarkNav || active,
            '!w-0': active,
          }"
        ></i>
        <i
          class="bottom-0"
          :class="{
            'bg-[#000]': isDarkNav && !active,
            'bg-[#fff]': !isDarkNav || active,
            'rotate-[30deg] -translate-y-1/2 !bottom-1/2': active,
          }"
        ></i>
      </button>
    </div>
    <!-- left fix menu -->
    <div
      class="transition-all fixed flex flex-col items-center left-[60px] opacity-0 pointer-events-none bottom-[30vh] text-[#000] p:space-y-[40px] tm:hidden"
      :class="{
        'text-[#000]': isDarkNav && !active,
        'text-[#fff]': !isDarkNav || active,
        'opacity-100 pointer-events-auto': y > topPos,
      }"
    >
      <router-link :to="{ name: 'product' }" class="vertical-right-to-left"
        >養蔘商品</router-link
      >
      <router-link :to="{ name: 'map' }" class="vertical-right-to-left"
        >店內諮詢</router-link
      >
      <button
        type="button"
        class="btn_fix_menu relative w-[12px] h-[30px]"
        @click="toggleMenu"
      >
        <i
          class="left-0"
          :class="{
            'bg-[#000]': isDarkNav && !active,
            'bg-[#fff]': !isDarkNav || active,
            'rotate-[-30deg] -translate-x-1/2 !left-1/2': active,
          }"
        ></i>
        <i
          class="right-0"
          :class="{
            'bg-[#000]': isDarkNav && !active,
            'bg-[#fff]': !isDarkNav || active,
            'rotate-[30deg] -translate-x-1/2 !right-1/2': active,
          }"
        ></i>
      </button>
    </div>
  </header>
</template>
<style lang="postcss" scoped>
.bg {
  background: url(@img/kv_bg.jpg) no-repeat;
  background-size: cover;
}
.btn_menu {
  > i {
    @apply block absolute w-full transition-all origin-top duration-[0.3s] ease-linear h-[1px];
  }
}
.btn_fix_menu {
  > i {
    @apply block absolute top-0 h-full transition-all origin-center duration-[0.3s] ease-linear w-[1px];
  }
}

.fix_nav {
  &::before {
    content: '';
    @apply transition-all block absolute -top-[80vw] left-0 w-full h-[100vw] rounded-br-full bg-[#a50101] bg-opacity-90 duration-[0.3s] opacity-0 ease-linear pointer-events-none;
  }
  &.is-active {
    &::before {
      @apply top-0 opacity-100 pointer-events-auto h-full rounded-none;
    }
  }
  li {
    &:not(:last-child) {
      &::after {
        content: '';
        @apply mx-auto transition-all block w-0 h-[1px] bg-[#fff] bg-opacity-50 duration-[0.8s] ease-linear;
      }
    }
    &.is-active {
      &:not(:last-child) {
        &::after {
          content: '';
          @apply p:w-[35%] tm:w-full;
        }
      }
    }
  }
}
</style>
